import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { navigateTo } from "gatsby-link";

function ContactPage() {
  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formState,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  const [formState, setFormState] = useState({
    name: "",
    message: "",
  });

  return (
    <Layout>
      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
        <SEO
          keywords={[
            `Tech companies in germany`,
            `Work in germany`,
            `information technology germany`,
          ]}
          title="Contact Us"
        />

        <section>
          <h1 className="block mb-2 text-lg font-bold uppercase mx-auto md:w-1/2">
            Contact Us
          </h1>
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className="mx-auto md:w-1/2"
          >
            <p className="mb-8 leading-loose">
              Know about a company that is not listed in the site ? just inform
              us about the company and we will add it as soon as possible to our
              list.
            </p>
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label className="block mb-2 text-xs font-bold uppercase">
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <p>
              <label className="block mb-2 text-xs font-bold uppercase">
                Your name:
                <br />
                <input
                  className="w-full mb-6 form-input"
                  id="name"
                  type="text"
                  name="name"
                  value={formState.name}
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <label className="block mb-2 text-xs font-bold uppercase">
                Message:
                <br />
                <textarea
                  className="w-full mb-6 form-textarea"
                  id="message"
                  name="message"
                  value={formState.message}
                  onChange={handleChange}
                />
              </label>
            </p>
            <p>
              <button className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600">
                Submit
              </button>
            </p>
          </form>
        </section>
      </main>
    </Layout>
  );
}

export default ContactPage;
